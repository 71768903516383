@use '@app/styles/typography';
@use '@app/styles/colors';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ecd5b2;
  padding: 16px;
  gap: 8px;
  background-color: var(--surface-secondary);
  width: 100%;
  border-radius: 16px;
}

.title {
  @extend %body;
}

.wrap {
  display: flex;
  gap: 11px;
}

.img {
  height: 49px;
}

.value {
  @extend %h-1;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d0ff58;
  @extend %badge-1;
  z-index: 3;
}
