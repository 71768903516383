@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  display: flex;
  flex-direction: row;
  gap: 11px;
  align-items: center;
  transform: translateX(-4px);
}

.coins {
  @include typography.h-1;
  color: #ecd5b2;
}

.img {
  width: 52px;
}

.jam,
.peach {
  align-items: center;
}

.tomato {
  align-items: center;

  .coins {
    @include typography.coin;
    color: var(--text-primary);
  }

  .img {
    width: 47px;
    padding: 0 3px 5px 4px;
  }
}

@media (max-height: 668px) {
  .img {
    width: 38px;
  }

  .coins {
    @include typography.h-2;
  }
}
