@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  @include mixins.max-width;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 60px;
  overflow-x: hidden;
  background-image: url('../../assets/media/skills-background.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  color: var(--text-primary);
}

.rankBadge {
  width: 92px;
  height: 92px;
  font-size: 40px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 16px 0 17px;
  width: 100%;
  text-align: center;
}

.chart {
  margin: 17px 0 32px;
}
