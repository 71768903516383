@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  position: relative;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}

.background {
  position: absolute;
  top: -25px;
  left: -25px;
  right: -20px;
  bottom: -20px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &.shake {
    animation: shake 1400ms 520ms ease-in-out forwards;
  }
}

.wheel {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.playersBar {
  @include mixins.max-width;
  padding: 0 4px;
  position: absolute;
  top: 4px;
  left: 0;
  z-index: 3;
}

.retreatButton {
  position: absolute;
  left: 16px;
  top: 178px;
  z-index: 1;
}

.resetButton {
  position: absolute;
  right: 16px;
  bottom: 178px;
  z-index: 999;
}

.tapArea {
  touch-action: none !important;
  position: absolute;
  width: 100%;
  height: 55%;
  bottom: 31%;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
}

.hpAnimation {
  top: 116px;
  left: 0;
  right: 0;
  z-index: 3;
}

.onboarding {
  position: absolute;
}

@keyframes shake {
  0% {
    background-position: center;
  }

  10% {
    background-position: -25px -2px;
  }

  20% {
    background-position: 15px 1px;
  }

  30% {
    background-position: -16px -3px;
  }

  40% {
    background-position: 24px 4px;
  }

  50% {
    background-position: -10px -3px;
  }

  60% {
    background-position: 2px 1px;
  }

  100% {
    background-position: center;
  }
}
