@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: var(--surface-primary);
  overflow: hidden;
}

.title {
  @include typography.title-16;
  padding: 16px 16px 0;
  color: var(--text-primary);
}

.items {
  display: flex;
  flex-direction: column;
}

.divider {
  width: 100%;
  border-bottom: 2px solid var(--border-primary);
}
