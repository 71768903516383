.root {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.image {
  width: 48px;
  height: 48px;
  position: absolute;
}
