@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  position: relative;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 344px;
  padding-bottom: 120px;
}

.balance {
  @include typography.subhead-main-big;
  color: var(--text-secondary);
  margin: 32px 0 8px;
}
