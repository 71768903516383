@use '@app/styles/typography';

.root {
  @include typography.subhead-main-little-medium;
  border-radius: 12px;
  background: #a2e830;
  padding: 10px 12px;
  color: var(--text-on-actions-dark);
  display: flex;
  gap: 8px;
  text-align: left;
  position: relative;

  b {
    font-weight: 700;
  }
}

.icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}
