@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100vh;
  overflow: hidden;

  background-image: url('../../assets/media/battle-background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.devHeader {
  padding: 5px;
  display: flex;
  gap: 8px;
  justify-content: center;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;

  * {
    font-size: 12px !important;
  }
}

.wormAvatar {
  @include mixins.max-width;
  position: absolute;
  overflow: hidden;
  height: 100%;
  bottom: 0;
}

.menu {
  margin-top: auto;
}
