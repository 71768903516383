@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  padding-top: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: var(--tg-viewport-height);
  position: relative;
}

.padding {
  @include mixins.side-paddings;
  display: flex;
  flex-direction: column;
}

.info {
  margin-top: 24px;
  margin-bottom: 24px;
}

.list {
  list-style-type: disc;
  padding-left: 20px;
}

.bottomButton {
  position: relative;
  margin-top: auto;
}
