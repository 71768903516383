.root {
  position: relative;
  width: 32px;
  height: 32px;
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--progress-gradient);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
}
