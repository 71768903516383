@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;
  padding-top: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  padding-bottom: 150px;
}

.tabs {
  margin-bottom: 16px;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  z-index: 1;
}

.title {
  @include typography.h-2;
  color: #ecd5b2;
  margin-bottom: 4px;
  text-align: center;
}

.desc {
  @include typography.body;
  color: #aa9088;
  text-align: center;
  margin-bottom: 16px;
}

.badge {
  align-self: center;
  margin-bottom: 4px;
}

.heroImg {
  height: 225px;
  flex-shrink: 0;
  z-index: 1;
  margin-bottom: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
}
