@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  @include mixins.max-width;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 60px;
  overflow-x: hidden;
  background-image: url('../../assets/media/skills-background.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  color: var(--text-primary);
}

.table {
  margin-top: 29px;
  width: 100%;
}
