@use 'media';

@mixin side-paddings {
  & {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include media.tablet-portrait {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@mixin max-width {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
