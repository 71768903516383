@use '@app/styles/typography';
@use '@app/styles/mixins';

.bottomSheet {
  align-items: center;
  text-align: center;
  padding-bottom: 32px;
  @include mixins.side-paddings;
}

.image {
  width: 240px;
  height: 140px;
  object-fit: contain;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
  margin-top: 16px;
  margin-bottom: 32px;
}

.wallet {
  margin-bottom: 16px;
}

.button {
  display: flex;
  align-self: stretch;
  margin-top: 32px;
}
