.root {
  width: 100%;
  padding-top: 95px;
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  row-gap: 60px;
  width: 100%;
  max-width: 420px;
  margin: auto;
}

.charWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  width: fit-content;
  overflow: visible;
  width: 100px;
  height: 127px;
}

.colSpan:nth-child(3n + 2) {
  transform: translateY(-90px);
}

.background {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: auto;
}

.avatarWrapper {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translate(-50%, 0);
  overflow: hidden;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  z-index: 1;
}

.avatar {
  width: 122px;
  height: 222px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: -9px;
  transform: translate(-50%, 0);
  z-index: 1;
}

.logo {
  width: 100px;
  height: 38px;
  object-fit: contain;
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.mysteryImage {
  width: 100px;
  height: 127px;
}
