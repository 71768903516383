@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 18px 33px 23px 23px;
  background-color: var(--surface-primary);
  gap: 6px;
}

.title {
  @extend %h-4;
  text-align: center;
  color: #ecd5b2;
  margin-bottom: 6px;
}

.desc {
  color: #aa9088;
  text-align: center;
  @extend %body;
  text-align: center;
  padding-bottom: 8px;
}

.rounded {
  border-radius: 16px;
}

.img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.bottom {
  border-radius: 0 0 16px 16px;
}
