@use '@app/styles/typography';
@use '@app/styles/colors';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: var(--icon-bg-size);
  margin-top: var(--margin-top);
}

.label {
  @extend %h-6;
  color: #ecd5b2;
  text-shadow: 0 1px 0 var(--text-stroke);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--text-stroke);
}

.imgWrap {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: var(--icon-bg-size);
  height: var(--icon-bg-size);
  border-radius: 50%;
  background: var(--icon-bg);
  z-index: 0;
}

.active {
  .activeBg {
    opacity: 1;
    animation: scale 0.4s;
  }
}
@keyframes scale {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.activeBg {
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--icon-bg-size);
  height: var(--icon-bg-size);
  background: var(--icon-active-bg);
  border-radius: 50%;
  transition: all 0.6s ease-in-out;
  z-index: 1;
}

.itemQnty {
  position: absolute;
  right: -8px;
  top: -8px;
  display: flex;
  height: 18px;
  width: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--decorative-background-red);
  @extend %days;
  color: #fff;
}
