@use '@app/styles/typography';

.root {
  position: absolute;
  font-family: var(--font-troika);
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  height: 80px;
}

.hpChange {
  position: absolute;
  opacity: 0;

  &.animate {
    animation: damageImpact 3s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
}

.left {
  left: 12px;
}

.right {
  right: 12px;
}

.hitForceGrey {
  color: var(--text-icn-secondary);
}

.hitForceOrange {
  color: var(--text-accent-orange);
}

.hitForceRed {
  color: var(--decorative-action-red);
}

@keyframes damageImpact {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.5) rotate(0deg);
  }

  20% {
    opacity: 1;
    transform: translateY(0) scale(1.1) rotate(5deg);
  }

  40% {
    transform: scale(1) rotate(0deg);
  }

  70% {
    opacity: 1;
    transform: translateY(-5px);
  }

  100% {
    opacity: 0;
    transform: translateY(50px) scale(0.8);
  }
}
