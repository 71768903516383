@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  transition: background-color 1s ease;
  position: relative;
  overflow: hidden;
}

.worm {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 600px);
  opacity: 0;
  transition: all 1s ease-out;
  pointer-events: none;

  &.loaded {
    opacity: 1;
    transform: translate(-50%, -50px);
  }

  &.preview.loaded {
    opacity: 1;

    transform: translate(-50%, 70px);
  }
}

.block,
.skills {
  position: absolute;
  bottom: 0;
  transform: translateY(500px);
  transition: all 0.5s 0.3s ease;

  &.loaded {
    transform: translateY(0);
  }
}

.logo {
  width: 100%;
  padding: 0 20px;
  margin-top: 25px;
  transition: all 0.5s 0.3s ease-out;
  transform: translateY(-300px);

  &.loaded {
    transform: translateY(0);
  }
}

.skills {
  background-color: var(--surface-primary);
  padding: 16px 16px 32px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
