@use '@app/styles/typography';

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
}

.label,
.item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 8px 8px 16px;
  border-radius: 16px;
  background-color: var(--surface-secondary);
  min-height: 65px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.completed {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #819d31;
}

.serialNum {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: var(--surface-primary);
  @extend %badge;
  color: #ecd5b2;
}

.title {
  @extend %title-2;
  color: #ecd5b2;
}

.desc {
  color: #aa9088;
  @extend %body;
}

.checkMark,
.icon {
  width: 24px;
  height: 24px;
}

.icon {
  color: #aa9088;
}

.disable {
  opacity: 0.6;

  .icon {
    display: none;
  }
}

.input {
  @extend %subtitle;
  outline: none;
  border: 0;
  color: #ecd5b2 !important;
  background-color: #27181b !important;
  width: calc(100% - 123px);
}

.input::placeholder {
  color: #ecd5b2 !important;
}

.input:active,
.input:focus,
.input:focus-visible {
  background-color: #27181b !important;
}

.btn {
  @extend %title-2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: #27181b;
  background-color: #ecd5b2;
  border-radius: 12px;
  width: 115px;
}

.loader {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 3px solid #ecd5b2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
