@use '@app/styles/typography';

.root {
  position: relative;
}

.menu {
  .progressValues {
    font-size: 14px;
  }
}

.leagueProgress {
  .frame {
    width: 155px;
  }

  .bar {
    width: 155px;
  }
}

.bar {
  position: absolute;
  top: 50%;
  height: calc(100% - 14px);
  width: 100%;
  padding: 0 3px;
  transform: translateY(-50%);
  border: 1px solid var(--border-on-action);
}

.frame {
  height: 30px;
  z-index: 2;
  position: relative;
  width: 100%;
}

.progressValues {
  @include typography.h-5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 110%;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--border-primary);
}
