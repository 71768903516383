@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  background-color: var(--surface-bg);
  padding: 2px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 2;
  width: 100%;

  &.withShadow {
    &::after {
      content: '';
      position: absolute;
      top: 52px;
      left: 0;
      right: 0;
      height: 16px;
      background: linear-gradient(180deg, var(--surface-bg), transparent);
    }
  }
}

.avatar {
  transform: translateY(5px);
}

.info {
  display: flex;
  flex-direction: column;
}

.userName {
  @include typography.subhead-main-small;
  color: var(--text-primary);
}

.points {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: auto;
  align-items: flex-end;
  align-content: flex-end;
}
