.root {
  position: relative;
}

.small .image {
  width: 140px;
  height: 80px;
  object-fit: cover;
  object-position: 50% 40%;
}

.normal .image {
  width: 200px;
  height: 200px;
}

.price {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-11deg);
}

.priceTag {
  padding: 6px 12px !important;
  gap: 6px;
}
