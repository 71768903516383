@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  @include mixins.max-width;
  @include mixins.side-paddings;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 32px;
  overflow-x: hidden;
  padding-bottom: 80px;
}

.title {
  @include typography.h-2;
}

.description {
  @include typography.subhead-main-big;
  color: var(--text-secondary);
  margin-top: 6px;
  margin-bottom: 14px;
  max-width: 294px;
}

.totalWorms {
  display: flex;
  align-items: center;
  gap: 11px;
}

.currency {
  width: 68px;
  height: 68px;
}

.amount {
  @include typography.h-1;
}
