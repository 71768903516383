@use '@app/styles/typography';

.root {
  border-radius: 16px;
  background: var(--surface-primary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  width: 100%;
  padding: 16px;
  position: relative;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  align-self: center;
}

.name {
  font-family: var(--font-inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--text-primary);
  margin: 10px 0 4px;
}

.bonus {
  margin-bottom: 8px;
}

.ownedTag {
  @include typography.subhead-main-micro;
  color: var(--text-icn-accent-green);
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
}

.starsPrice {
  margin-top: 4px;
}
