@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  @include typography.subhead-main-big;
  margin-bottom: 8px;
}

.items {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: var(--surface-primary);
  overflow: hidden !important;
}

.fighter {
  padding: 4px 16px 4px 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  border-bottom: 1px solid var(--border-primary);

  &:last-child {
    border: 0;
  }
}

.avatar {
  transform: translateY(5px) scaleX(-1);
}

.fighterInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1px;
  flex: 1 1 auto;
  margin-right: auto;
  padding-right: 16px;
}

.fighterName {
  @include typography.subhead-main-big;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.stat {
  @include typography.subhead-main-micro;
  color: var(--text-secondary);
}

.initialLoader {
  width: 80px;
  height: 80px;
  margin: 60px auto;
}

.loader {
  width: 60px;
  height: 60px;
  margin: 20px auto;
}

.emptyList {
  @include typography.body-small;
  text-align: center;
  padding: 40px 20px;
}

.amountTag {
  &.grey {
    background-color: var(--action-secondary-base-disabled) !important;
  }
}
