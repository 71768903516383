@mixin h-1 {
  font-family: var(--font-troika);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
}

%h-1 {
  @include h-1;
}

@mixin h-1-extra {
  font-family: var(--font-troika);
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

%h-1-extra {
  @include h-1-extra;
}

@mixin h-2 {
  font-family: var(--font-troika);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

%h-2 {
  @include h-2;
}

@mixin h-2-extra {
  font-family: var(--font-troika);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

%h-2-extra {
  @include h-2-extra;
}

@mixin h-3 {
  font-family: var(--font-troika);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.2px;
}

%h-3 {
  @include h-3;
}

@mixin h-4 {
  font-family: var(--font-troika);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

%h-4 {
  @include h-4;
}

@mixin h-5 {
  font-family: var(--font-troika);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

%h-5 {
  @include h-5;
}

@mixin h-6 {
  font-family: var(--font-troika);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

%h-6 {
  @include h-6;
}

@mixin coin {
  font-family: var(--font-troika);
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 107%;
}

%coin {
  @include coin;
}

@mixin label {
  font-family: var(--font-troika);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
}

%label {
  @include label;
}

@mixin title {
  font-family: var(--font-rubik);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

%title {
  @include title;
}

@mixin title-1 {
  font-family: var(--font-rubik);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}

%title-1 {
  @include title-1;
}

@mixin title-2 {
  font-family: var(--font-rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}

%title-2 {
  @include title-2;
}

@mixin title-3 {
  font-family: var(--font-rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

%title-3 {
  @include title-3;
}

@mixin title-16 {
  font-family: var(--font-rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

%title-16 {
  @include title-16;
}

@mixin subtitle {
  font-family: var(--font-rubik);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

%subtitle {
  @include subtitle;
}

@mixin body {
  font-family: var(--font-rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

%body {
  @include body;
}

@mixin body1 {
  font-family: var(--font-rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

%body1 {
  @include body1;
}

@mixin alert {
  font-family: var(--font-rubik);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38;
}

%alert {
  @include alert;
}

@mixin button {
  font-family: var(--font-troika);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.08px;
}

%button {
  @include button;
}

@mixin badge {
  font-family: var(--font-rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
}

%badge {
  @include badge;
}

@mixin badge-1 {
  font-family: var(--font-rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

%badge-1 {
  @include badge-1;
}

@mixin badge-2 {
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

%badge-2 {
  @include badge-2;
}

@mixin tab {
  font-family: var(--font-rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

%tab {
  @include tab;
}

@mixin tab-active {
  font-family: var(--font-rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

%tab-active {
  @include tab-active;
}

@mixin value {
  font-family: var(--font-rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
}

%value {
  @include value;
}

@mixin league {
  font-family: var(--font-rubik);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
}

%league {
  @include league;
}

@mixin timer-big {
  font-family: var(--font-troika);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
}

%timer-big {
  @include timer-big;
}

@mixin days {
  font-family: var(--font-rubik);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

%days {
  @include days;
}

@mixin pending {
  font-family: var(--font-rubik);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

%pending {
  @include pending;
}

@mixin days-widget {
  font-family: var(--font-rubik);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}

%days-widget {
  @include days-widget;
}

/////

@mixin friend-name {
  font-family: var(--font-rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

%friend-name {
  @include friend-name;
}

@mixin subhead-main-big {
  font-family: var(--font-rubik);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}

%subhead-main-big {
  @include subhead-main-big;
}

@mixin subhead-main-little-medium {
  font-family: var(--font-rubik);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

%subhead-main-little-medium {
  @include subhead-main-little-medium;
}

@mixin subhead-main-small {
  font-family: var(--font-rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

%subhead-main-small {
  @include subhead-main-small;
}

@mixin subhead-main-micro {
  font-family: var(--font-rubik);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

%subhead-main-micro {
  @include subhead-main-micro;
}

@mixin subhead-main-little-semibold {
  font-family: var(--font-rubik);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

%subhead-main-little-semibold {
  @include subhead-main-little-semibold;
}

@mixin body-little {
  font-family: var(--font-rubik);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

%body-little {
  @include body-little;
}

@mixin body-small {
  font-family: var(--font-rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

%body-small {
  @include body-small;
}
