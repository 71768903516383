.root {
  position: relative;
  height: 8px;
  width: 100%;
  background-color: var(--surface-secondary);
  border-radius: 16px;
  overflow: hidden;
  margin: 3px 0 4px;
}

.firstFill,
.secondFill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 16px;
  transition: all 0.5s ease-out;
}

.firstFill {
  background-color: var(--border-accent);
}

.secondFill {
  background-color: var(--action-secondary-disabled);
}
