@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  gap: 48px;
  border-radius: 16px;
  justify-content: center;
  background-color: var(--surface-primary);
  width: 100%;
  flex-shrink: 0;
  position: relative;
  padding: 16px;
}

.navButton {
  position: absolute;
  top: 16px;
  color: var(--text-primary);
  z-index: 1;

  &:disabled .arrow {
    color: var(--action-secondary-base-disabled);
  }
}

.prev {
  left: 16px;
  transform: scaleX(-1);
}

.next {
  right: 16px;
}

.arrow {
  width: 24px;
  height: 24px;
  color: var(--text-primary);
}

.claimBtn {
  margin-top: auto;
  margin-bottom: 10px;
  transform: translateY(10px);
}

.rewards {
  display: flex;
  justify-content: center;
}

.rewardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 18px;
  width: 108px;
  height: 108px;
  flex-shrink: 1;

  &:first-of-type,
  &:last-of-type {
    background-color: var(--surface-secondary);
  }

  &:first-of-type {
    transform: rotate(-15deg) translateY(24px);
  }

  &:last-of-type {
    transform: rotate(15deg) translateY(24px);
  }

  &:nth-of-type(2) {
    background-color: var(--surface-tertiary);
    z-index: 1;
  }
}

.rewardImage {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.rewardText {
  @include typography.subhead-main-little-semibold;
  text-align: center;
}

.unlockText {
  @include typography.subhead-main-small;
  text-align: center;
  margin: auto auto 0;
  color: var(--text-secondary);
  max-width: 269px;
}
