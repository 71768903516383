@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.text {
  @include typography.days;
  color: #aa9088;
}

.mark {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--action-secondary-base-disabled);
  width: 24px;
  height: 24px;
}

.completed {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #d0ff58;
  width: 24px;
  height: 24px;
}

.iconContainer {
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
}

.lastDayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
}

.completed {
  width: 24px;
  height: 24px;
}
