.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 32px;
}

.list {
  gap: 32px;
}

.imgWrap {
  position: relative;
  max-width: 326px;
  width: 100%;
  height: 209px;
}

.img {
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translate(-50%);
  max-width: 326px;
  width: 100%;
  object-fit: contain;
}
