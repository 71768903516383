@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  @include mixins.max-width;
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background-image: url('../../assets/media/earn-hero-bg.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  color: var(--text-primary);
}

.content {
  height: 100%;
}
