@use '@app/styles/typography';

.root {
  width: 100%;
  max-width: 100%;
  margin-top: 16px;
}

.content {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-on-actions-dark);
}

.stars {
  @include typography.subhead-main-little-semibold;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background-color: var(--surface-tertiary);
  color: var(--text-primary);
}

.icon {
  width: 16px;
  height: 16px;
}
