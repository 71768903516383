@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  @include typography.subhead-main-big;
  margin-bottom: 8px;
}

.earnings {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: var(--surface-primary);
  padding: 4px 0;
}

.available {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: var(--surface-primary);
  padding: 4px 0 16px;
  margin-top: 8px;
}

.devButton {
  margin-top: 24px;
}
