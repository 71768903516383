@use '@app/styles/typography';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none !important;
  pointer-events: none;
  transition: transform 0.3s ease-out;

  &.hide {
    transform: translate(50px, 50px);
  }
}

.container {
  --progress: 100;

  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.progressBg {
  position: absolute;
  width: 95%;
  height: 95%;
  background-color: var(--surface-primary);
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.progress {
  --angle: calc(3.6deg * var(--progress, 0));

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  clip-path: circle(50% at 50%);
  z-index: 1;
}

.cover {
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2;
  width: 68px;
  height: 68px;
}
