@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/media/battle-background.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.3;
  }
}

.logoSlap,
.logoWormfare,
.noHamsters,
.text,
.progress {
  position: relative;
}

.logoWormfare {
  width: 150px;
  height: 42px;
}

.logoSlap {
  width: 223px;
  height: 128px;
}

.noHamsters {
  width: 122px;
  height: 124px;
  margin-top: 76px;
  margin-bottom: 6px;
}

.text {
  font-family: var(--font-troika);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--text-accent-orange);
}

.status {
  margin-top: 61px;
  font-family: var(--font-troika);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--text-icn-accent-green);
  margin-bottom: 2px;
  position: relative;
}

.progress {
  width: 293px;
}
