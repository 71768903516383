/* stylelint-disable property-no-vendor-prefix */
@use '@app/styles/typography';

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 0;
  transition:
    filter 0.3s ease,
    opacity 0.3s ease;

  &.primary {
    background-color: var(--primary-btn-base);

    .wrap {
      background-color: var(--primary-btn);
    }

    .rhombus {
      position: absolute;
      left: 43px;
      bottom: -6.8px;
    }
  }

  &.secondary {
    background-color: var(--secondary-btn-base);

    .wrap {
      background-color: var(--secondary-btn);
    }

    .rhombus {
      position: absolute;
      left: 43px;
      bottom: -10px;
    }
  }
}

.secondary,
.primary {
  @extend %h-5;
  min-height: 51px;
  border-left: 1px solid var(--primary-btn-label);
  border-right: 1px solid var(--primary-btn-label);
  border-bottom: 2px solid var(--primary-btn-label);
  border-radius: 12px;
  background:
    left 4px bottom -1px / contain no-repeat url('~@media/button/btn-bg-01.png'),
    right 4px bottom -1px / contain no-repeat
      url('~@media/button/btn-bg-02.png');
  color: var(--primary-btn-label);

  .content {
    padding: 0 6px;
  }

  &:active {
    .wrap {
      transform: translateY(0) translateZ(1em);
    }

    .iconLeft,
    .iconRight {
      transform: translateY(9px) translateZ(2em);
    }
  }

  &:disabled {
    pointer-events: none;
    filter: brightness(0.3);

    .wrap {
      transform: translateY(-9px) translateZ(1em);
    }

    .iconLeft,
    .iconRight {
      transform: translateY(9px) translateZ(2em);
    }
  }

  .wrap {
    background:
      left 4px top 2px / contain no-repeat url('~@media/button/btn-bg-1.png'),
      right 4px top 2px / contain no-repeat url('~@media/button/btn-bg-2.png');

    position: absolute;
    display: flex;
    bottom: 0;
    align-items: center;
    justify-content: center;
    width: calc(100% + 2px);
    height: 51px;

    border-radius: 12px 12px 4px 4px;
    border: 1px solid var(--primary-btn-label);

    transform: translateY(-9px) translateZ(1em);
    transition: transform 0.15s;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 5px;
    text-align: center;
    user-select: none;
  }
}

.secondary-medium,
.primary-medium {
  width: 100%;
  max-width: 155px;
  height: 51px;
}

.secondary-large,
.primary-large {
  width: 100%;
  height: 51px;
}

.tertiary-small {
  width: auto;
  height: 40px;
}

.tertiary-medium {
  width: 100%;
  max-width: 194px;
  height: 40px;
}

.tertiary-large {
  width: 100%;
  height: 40px;
}

.unfilled {
  @include typography.label;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
  color: var(--green-label);

  .content {
    display: flex;
    align-items: center;
    text-align: center;
    text-shadow: unset;
    -webkit-text-stroke-width: unset;
    user-select: none;
  }
}

.tertiary {
  @include typography.h-6;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background: var(--bg-color);
  color: var(--primary-btn-label);
  gap: var(--gap);

  .content {
    display: flex;
    align-items: center;
    text-align: center;
    text-shadow: unset;
    -webkit-text-stroke-width: unset;
    user-select: none;
  }

  .iconRight,
  .iconLeft {
    flex-shrink: 0;
  }

  &:active {
    filter: brightness(1.3);
  }
}

.indicator {
  position: absolute;
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f00;
  z-index: 10;
  right: 0.5px;
  top: 0.5px;

  animation: pulseIndicator 2.5s infinite linear;
}

@keyframes pulseIndicator {
  0% {
    scale: 1;
  }

  50% {
    scale: 0.8;
  }

  100% {
    scale: 1;
  }
}

.disabled {
  opacity: 0.3;
}

.primary {
  &.green {
    background-color: var(--primary-btn-base);

    .wrap {
      background-color: var(--primary-btn);
    }
  }

  &.grey {
    background-color: var(--action-secondary-base-default);

    .wrap {
      background-color: var(--action-secondary-default);
    }
  }
}
