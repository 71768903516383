@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--surface-primary);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  margin-bottom: 16px;
  text-align: left;
  gap: 16px;
}

.label {
  @include typography.subhead-main-big;
  color: var(--text-primary);
}

.items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
