@use '@app/styles/typography';
@use '@app/styles/colors';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 16px;
  gap: 16px;
  border-radius: 16px;
  background: var(--surface-primary);
}

.title {
  color: #ecd5b2;
  @extend %h-3;
  text-align: center;
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d0ff58;
  @extend %badge-1;
  z-index: 3;
}
