@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @extend %h-2;
  color: #ecd5b2;
  margin-bottom: 4px;
  text-align: center;
}

.subtitle {
  @extend %subtitle;
  color: #ecd5b2;
  text-align: center;
}

.desc {
  margin-top: 24px;
  color: #aa9088;
  @extend %body;
  text-align: center;
}

.badge {
  align-self: center;
  margin-bottom: 10px;
}

.img {
  width: 240px;
  height: 140px;
  margin-bottom: 16px;
  object-fit: contain;
}

.reward {
  margin-top: 32px;
}

.button {
  margin-top: 41px;
}

.limited {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.limitedDesc {
  color: #ecd5b2;
  @extend %body;
  font-weight: 600;
}

.available {
  @extend %body;
  color: #aa9088;
  display: flex;
  gap: 6px;
}

.loaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
  width: 100%;
}

.loader {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 3px solid #ecd5b2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
