:root {
  --progress-track-color: #27181b;
  --progress-color: #ecd5b2;
}

.track {
  fill: none;
  stroke: var(--progress-track-color);
}

.progress {
  fill: none;
  stroke: var(--progress-color);
  stroke-linecap: round;
  stroke-linejoin: round;
}
