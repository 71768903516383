@use '@app/styles/mixins';

.root {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.content {
  @include mixins.side-paddings;
}

.info {
  background-color: var(--action-secondary-base-default);
  margin-top: 14px;
}

.menu {
  margin-top: auto;
  margin-bottom: 8px;

  &.isIOS {
    margin-bottom: 24px;
  }
}

.wormAvatar {
  position: absolute;
  bottom: -85px;
}

.changeButton {
  bottom: 110px;
}

.equipButton {
  bottom: 116px;
}
