@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: 16px;
  padding-bottom: 120px;
  height: 100%;
  text-align: center;
  background-image: url('../../assets/media/earn-hero-bg.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
}

.description {
  @include typography.subhead-main-big;
  color: var(--text-secondary);
  margin-top: 6px;
  margin-bottom: 8px;
}

.coming {
  font-family: var(--font-troika);
  font-size: 16px;
  color: var(--text-accent-orange);
}

.image {
  width: 258px;
  height: 172px;
  margin: 40px auto;
}
