@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;
  background-image: url('../../assets/media/earn-hero-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 50px;
}

.reward {
  margin-bottom: 12px;
}

.arrow {
  color: var(--text-icn-accent-green);
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
  align-self: center;
  flex-shrink: 0;
}

.battleList {
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
  width: 100%;
}
