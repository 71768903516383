@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.animationTarget {
  position: absolute;
  top: 0;
  width: 65%;
  height: 50px;
  left: 30%;
  transform: translateX(-50%);
  pointer-events: none;
}
