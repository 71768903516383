@use '@app/styles/typography';

.root {
  position: relative;
  display: flex;
  width: 100%;
  z-index: 0;
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding: 0 16px;
  width: 100%;
  z-index: 1;
  padding-bottom: env(safe-area-inset-bottom, 124px);
}

.bg {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  background: var(--surface-secondary);
  box-shadow: 0 0 15px 0 #191512;
  width: 120vw;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.img {
  z-index: 3;
}

.item {
  display: flex;
  z-index: 2;
}
