@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  visibility: hidden;

  .content {
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 16px 32px;
    border-radius: 20px;
    max-width: 100%;
  }

  .cover {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.title {
  max-width: 70%;
  @extend %h-2;
  text-align: center;
  color: #ecd5b2;
  margin-top: 24px;
  margin-bottom: 48px;
}

.open {
  z-index: 100;
  opacity: 1;
  visibility: visible;

  .cover {
    opacity: 1;
    visibility: visible;
  }
}

.closeBtn {
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: rgba(94, 63, 44, 1);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  z-index: 10;
}

.content {
  background: #27181b;
}

.btnGroup {
  display: flex;
  justify-content: center;
  gap: 12px;
}
