@use '@app/styles/typography';

.root {
  @include typography.subhead-main-little-medium;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 12px;
  background: var(--surface-secondary);
  color: var(--text-secondary);
}

.address {
  color: var(--text-primary);
}
