@use '@app/styles/typography';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small {
  .loader {
    width: 18px;
    height: 18px;
    border: 2px solid #ecd5b2;
  }
}

.medium {
  .loader {
    width: 24px;
    height: 24px;
    border: 3px solid #ecd5b2;
  }
}

.loader {
  flex-shrink: 0;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
