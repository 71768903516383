@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: var(--surface-primary);
  text-align: center;
  padding: 16px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  max-width: 500px;
  width: 100%;

  &.isDefeat {
    background-color: var(--surface-bg);
  }
}

.background {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  filter: blur(200px);

  &.redBg {
    background: var(--decorative-action-red);
  }

  &.greenBg {
    background: var(--text-icn-accent-green);
  }
}

.title {
  @include typography.h-3;
  color: var(--text-primary);
}

.reward {
  @include typography.subhead-main-little-semibold;
  display: flex;
  gap: 4px;
  color: var(--text-icn-primary-active);
  margin-top: 4px;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
}

.description {
  @include typography.subhead-main-small;
  color: var(--text-secondary);
  margin-top: 18px;
}

.avatar {
  position: absolute;
  height: 283px;
  width: auto;
  top: 65px;
  left: 50%;

  &.user {
    transform: translateX(calc(-50% - 75px)) scaleX(-1);
  }

  &.opponent {
    transform: translateX(calc(-50% + 75px));
  }
}

.spacer {
  height: 176px;
}

.foregroundImg {
  width: 550px;
  position: absolute;
  top: -180px;
}

.button {
  width: 100%;
  margin-top: 12px;
}

.statusImage {
  position: relative;
  margin-bottom: 8px;
  object-fit: cover;

  &.tie {
    width: 200px;
    height: 49px;
    object-position: center bottom;
  }

  &.defeat {
    width: 260px;
    height: 63px;
    object-position: center top;
  }

  &.victory {
    width: 270px;
    object-position: center bottom;
    height: 72px;
    margin-bottom: 0;
  }
}

.retreatText {
  @include typography.subhead-main-small;
  color: var(--text-secondary);
  position: relative;
  margin: 8px 0;
}

.stayInPhase {
  margin-bottom: 14px;
}
