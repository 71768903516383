@use '@app/styles/typography';

.bottomSheet {
  align-items: center;
  text-align: center;
  padding-bottom: 32px;
}

.image {
  width: 240px;
  height: 140px;
  object-fit: contain;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
  margin-bottom: 4px;
}

.description {
  @include typography.subhead-main-big;
  color: var(--text-primary);
}

.info {
  background-color: var(--action-secondary-base-default);
  margin-top: 32px;
}

.button {
  align-self: stretch;
  margin-top: 32px;
}
