@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;
  @include mixins.max-width;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;
}

.reward {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px;
  border-radius: 8px;
  background: var(--surface-primary);
  z-index: 3;
  position: relative;
}

.multiplier {
  @include typography.h-3;
  display: flex;
  align-items: center;
  color: var(--text-accent-orange);
}

.multiplierIcon {
  width: 16px;
  height: 16px;
  object-fit: contain;
  fill: var(--text-accent-orange);
}

.rewardName {
  @include typography.h-4;
  color: var(--text-primary);
  flex-grow: 1;
}

.points {
  @include typography.h-3;
  color: var(--text-primary);
}

.imageWrapper {
  position: relative;
}

.image {
  width: 24px;
  height: 24px;
}

.topUpAnimation {
  top: -2px;
  left: 0;
}
