@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  background-color: var(--surface-primary);
  overflow: hidden;
}

.wrap {
  padding: 16px 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  color: #ecd5b2;
  @extend %h-2;
  margin-bottom: 20px;
}

.desc {
  color: #aa9088;
  text-align: center;
  @extend %body;
  margin-bottom: 8px;
}

.badge {
  margin-bottom: 172px;
}

.img {
  width: 100%;
}
