@use '@app/styles/typography';

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &.normal {
    @include typography.subhead-main-little-semibold;
  }

  &.big {
    font-family: var(--font-rubik);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    .tag {
      padding: 4px 8px;
    }
  }

  &.stars {
    @include typography.subhead-main-micro;
    color: var(--text-secondary);

    .tag {
      padding: 0;
    }
  }
}

.tag {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 50px;
  position: relative;

  &.discounted {
    opacity: 0.5;

    &::after {
      content: '';
      position: absolute;
      width: 85%;
      height: 2px;
      background-color: var(--text-primary);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-8deg);
    }
  }
}
