@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include typography.subhead-main-micro;
  display: flex;
  padding: 0 4px 0 8px;
  align-items: center;
  gap: 4px;
  border-radius: 50px;
  background: var(--control-primary-default);
  color: var(--text-secondary);
}

.highlight {
  color: var(--text-primary);
}
