@use '@app/styles/media';
@use '@app/styles/typography';

.root {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
}

.badgesContainer {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.rankWrapper {
  position: relative;
  margin-right: 125px;
  padding-bottom: 24px;

  &:last-child {
    margin-right: 0;
  }
}

.leagueName {
  @include typography.h-4;
  color: var(--text-primary);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.spacer {
  width: 60vw;
  flex-shrink: 0;
}
