.root {
  width: 192px;
  height: 192px;
  object-fit: contain;
  margin: auto;
  transform: translateY(300px) scale(0);
  transition: all 0.5s ease;
  opacity: 0;

  &.loaded {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
