@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 32px;
  z-index: 0;
  pointer-events: none;
}

.value {
  @extend %h-4;
  color: var(--text-primary);
}

.label {
  color: var(--text-primary);
  @extend %label;
}
