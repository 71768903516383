@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
  width: 100%;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.title {
  @extend %h-2;
  text-align: center;
  color: var(--text-primary);
  margin-bottom: 6px;
  max-width: 294px;
}

.desc {
  color: var(--text-secondary);
  text-align: center;
  @extend %subhead-main-big;
  text-align: center;
  padding-bottom: 8px;
  max-width: 294px;
}

.img {
  object-fit: contain;
  z-index: 1;
  margin: var(--img-margin);
}

.heroImgWrap {
  position: absolute;
  left: 50%;
  top: -16px;
  transform: translate(-50%, 0);
  height: 346px;
  width: calc(100% + 32px);
  z-index: 0;
  background:
    linear-gradient(180deg, #181411 22.03%, rgba(24, 20, 17, 0) 79.37%),
    linear-gradient(180deg, rgba(24, 20, 17, 0) 63.59%, #181411 100%),
    url('../../assets/media/earn-hero-bg.png') no-repeat;
  background-position: center;
  background-size: cover;
}

.coming {
  @extend %h-6;
  color: var(--text-accent-orange);
}
