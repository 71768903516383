.root {
  padding-bottom: 32px;
}

.image {
  top: 10%;
  transition-delay: 0.15s !important;

  @media (min-height: 650px) {
    top: 25%;
  }

  @media (min-height: 750px) {
    top: 30%;
  }
}

.purchaseItemBlock {
  padding: 0;
  border-radius: 0;
  border-radius: 0;
}
