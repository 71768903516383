@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;

  & {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 16px;
    padding-bottom: 150px;
    height: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  gap: 24px;
}

.list {
  gap: 15px;
}

.listWrap {
  background-color: var(--surface-primary);
  padding: 16px;
  border-radius: 16px;
}

.questLate {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 50px 0;
}

.questLateText {
  @extend %h-2;
  color: #ecd5b2;
  margin-bottom: 4px;
  text-align: center;
}
