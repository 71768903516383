@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 16px;
  border-radius: 16px;
  background-color: var(--surface-primary);
}

.title {
  color: #ecd5b2;
  @extend %h-2;
  margin-bottom: 20px;
}

.green {
  color: #d0ff58;
}

.desc {
  color: #aa9088;
  text-align: center;
  @extend %body;
  margin-bottom: 24px;
}

.img {
  height: 159px;
  object-fit: contain;
  margin-bottom: 24px;
}
