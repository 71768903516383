.root {
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
}

.errorIcon {
  width: 50%;
  height: auto;
  color: var(--decorative-background-red);
}
