.root {
  position: relative;
  color: var(--text-primary);
}

.battleResult {
  padding-right: 138px;

  .bar {
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: calc(100% - 47px);
  }
}

.menu {
  .bar {
    bottom: -6px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 62px;
    z-index: 1;
  }
}

.leagueProgress {
  .bar {
    position: absolute;
    top: 50%;
    left: 49px;
    transform: translate(0, -50%);
    width: 155px;
  }
}

.badge {
  z-index: 1;
}

.bar {
  position: absolute;
}
