@use '@app/styles/typography';

.root {
  border-radius: 16px;
  background: var(--surface-primary);
  display: flex;
  align-items: center;
  padding: 16px;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 0 1px 1px transparent;
  transition: box-shadow 0.3s ease;
  flex-shrink: 0;
  position: relative;

  &.selected {
    box-shadow: 0 0 1px 1px var(--border-accent);
    background-color: var(--surface-secondary);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.wormColor {
  width: 129px;
  height: 129px;
  border-radius: 50%;
  position: relative;
  margin-top: 34px;
}

.avatarWrapper {
  position: absolute;
  height: 156px;
  width: 100%;
  top: -27px;
  border-bottom-left-radius: 45%;
  border-bottom-right-radius: 45%;
  overflow: hidden;
}

.avatarImage {
  position: absolute;
  width: 156px;
  height: 284px;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.avatarLoader {
  top: 100px;
}

.avatarNameImage {
  width: 129px;
  height: 46px;
  margin-top: -24px;
  margin-bottom: 16px;
  position: relative;
}

.avatarNameLoader {
  width: 24px;
  height: 24px;
}

.skills {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.price {
  margin-top: 16px;
  justify-self: flex-end;
}

.label {
  @include typography.subhead-main-micro;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);

  &.owned {
    color: var(--text-icn-accent-green);
  }

  &.limited {
    color: var(--text-accent-orange);
  }

  &.soldOut {
    color: var(--text-secondary);
  }
}

.starsPrice {
  margin-top: 4px;
}
