@use '@app/styles/typography';

.root {
  align-items: stretch;
  text-align: center;
  padding-bottom: 32px;
  color: var(--text-primary);
}

.title {
  @include typography.h-2;
  margin-bottom: 4px;
}

.description {
  @include typography.subhead-main-big;
}

.confirm {
  margin-top: 32px;
}
