@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  background-color: var(--surface-primary);
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

.title {
  @include typography.subhead-main-big;
  color: var(--text-primary);
}

.slappers {
  @include typography.h-5;
  display: flex;
  align-items: baseline;
  gap: 6px;
  color: var(--text-secondary);
}

.amount {
  @include typography.h-2;
  color: var(--text-primary);
}

.pending {
  @include typography.pending;
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--text-accent-orange);
}

.img {
  position: absolute;
  object-fit: contain;
  right: 20px;
  top: 16px;
  width: 69px;
  height: 101px;
}
