@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  gap: 18px;
  background-color: var(--surface-primary);
  width: 100%;
  border-radius: 16px;
  text-align: left;
}

.title {
  @include typography.subhead-main-big;
}

.link {
  @include typography.subhead-main-little-medium;
  color: var(--text-secondary);
  word-break: break-all;
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background: var(--surface-secondary);

  b {
    color: var(--text-primary);
  }
}

.btnWrap {
  width: 100%;
  display: flex;
  gap: 16px;
}

.copy {
  flex: 1 1 50%;
}

.share {
  flex: 1 1 12%;
}
