@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;

  background-image: url('../../assets/media/battle-background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.worm {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 600px);
  opacity: 0;
  transition: all 1s ease-out;
  pointer-events: none;

  &.loaded {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.block {
  position: absolute;
  bottom: 0;
  transform: translateY(500px);
  transition: all 0.5s 0.3s ease;

  &.loaded {
    transform: translateY(0);
  }
}
