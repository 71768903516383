@use '@app/styles/typography';

.root {
  border-radius: 16px;
  background: var(--surface-primary);
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  position: relative;
}

.title {
  @include typography.h-3;
  color: var(--text-primary);
}

.cryptoReward {
  width: 200px;
  height: 200px;
}

.rewards {
  display: flex;
  gap: 8px;
  width: 100%;
}

.reward {
  @include typography.subhead-main-little-semibold;
  padding: 8px 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 auto;
  border-radius: 18px;
  background: var(--surface-secondary);
  color: var(--text-primary);

  &.single {
    background: unset;
  }

  &.single .rewardImg {
    width: 200px;
    height: 200px;
  }
}

.rewardImg {
  width: 84px;
  height: 84px;
  object-fit: contain;
}

.entryNote {
  @include typography.subhead-main-small;
  color: var(--text-secondary);
}

.entry {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--text-primary);
  width: 100%;
}

.entryTitle {
  @include typography.h-4;
}

.entryCard {
  border-radius: 16px;
  background: var(--surface-secondary);
  padding: 0 16px;
  width: 100%;
}

.button {
  width: 100%;
  margin-top: 14px;
}

.completed {
  @include typography.h-4;
  color: var(--text-primary);
}

.youHave {
  @include typography.subhead-main-little-medium;
  color: var(--text-secondary);
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin: 0 4px 0 8px;
  }

  span {
    @include typography.subhead-main-small;
    color: var(--text-primary);
  }
}
