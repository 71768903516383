@use '@app/styles/typography';
@use '@app/styles/colors';

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.content {
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;
  border-radius: 20px 20px 0 0;
  width: 100%;
  transform: translateY(100%);
  transition: all 0.5s;
}

.cover {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.4s;
}

.open {
  z-index: 100;
  bottom: 0;
  left: 0;
  opacity: 1;
  visibility: visible;

  .content {
    transform: translateY(0);
  }

  .cover {
    opacity: 1;
    visibility: visible;
  }
}

.closeBtn {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
}

.deep-brown {
  .content {
    background: #110007;
  }
}

.gradient {
  .content {
    background: linear-gradient(180deg, #110007 0%, #27181b 100%);
  }
}

.surface-primary {
  background: var(--surface-primary);
}
