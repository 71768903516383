.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border-radius: 16px;
  transition: all 0.5s ease-in-out;
  width: 100%;
  aspect-ratio: 1;
  padding: 16px;

  &.deselect {
    background-color: var(--surface-primary);
  }

  &.item {
    background-color: var(--surface-primary);
    border: 1px solid transparent;
  }

  &.active {
    pointer-events: none;
    border: 1px solid var(--border-accent);
  }

  &.disabled {
    pointer-events: none;
    filter: brightness(0.9);
  }
}

.deselectIcon {
  width: 78%;
  max-width: 78px;
  height: auto;
  color: var(--action-secondary-base-disabled);
}

.itemImage {
  width: 78%;
  height: auto;
  max-width: 100px;
  object-fit: contain;
}

.bonus {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.amount {
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--text-accent-orange);
}

.bonusIcon {
  width: 16px;
  height: 16px;
}
