@use '@app/styles/typography';

.root {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.or {
  @include typography.h-3;
  color: var(--text-accent-orange);
  padding: 16px;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 16px;
  background: var(--surface-secondary);
  width: 100%;
  gap: 12px;
}

.cardTitle {
  @include typography.subhead-main-small;
  color: var(--text-primary);

  span {
    color: var(--text-icn-accent-green);
  }
}

.priceWrapper {
  border-radius: 16px;
  background: var(--surface-tertiary);
  padding: 0 16px;
  margin-bottom: 16px;
}
