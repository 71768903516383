@use '@app/styles/typography';

.defult {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.widget {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--surface-secondary);
}

.text {
  @extend %days-widget;
  color: #ecd5b2;
}

.green {
  color: #d0ff58;
}
