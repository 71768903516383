/* stylelint-disable selector-class-pattern */
@use '@app/styles/typography';
@use '@app/styles/mixins';

.toastContainer {
  width: 100%;
  padding: 16px 16px 0;
}

.customToast {
  background: var(--surface-primary);
  box-shadow: none;
  color: inherit;
  min-height: 0;
  max-height: none;
  font-family: inherit;
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  gap: 10px;
  white-space: pre-wrap;
}

.customBody {
  padding: 0;
}
