@use '@app/styles/typography';

.root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
}

.col {
  display: flex;
  flex-direction: column;
}

.title {
  @include typography.subhead-main-small;
  display: flex;
  gap: 4px;
  align-items: center;

  &.pending {
    color: var(--text-accent-orange);
  }
}

.description {
  @include typography.body-little;
  color: var(--text-secondary);
}

.amount {
  @include typography.body-small;
  color: var(--text-icn-primary-active);
  align-self: flex-end;
  display: flex;
  gap: 4px;
  align-items: center;
}

.usdEquivalent {
  @include typography.body-little;
  color: var(--text-secondary);
  align-self: flex-end;
  transition: opacity 0.3s ease;

  &.hidden {
    opacity: 0;
  }
}

.tonIcon {
  transform: translateY(-1px);
  width: 16px;
  height: 16px;
}

.loader {
  width: 24px;
  height: 24px;
}
