@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @extend %h-2;
  color: #ecd5b2;
  margin-bottom: 4px;
  text-align: center;
}

.desc {
  margin-top: 24px;
  color: #aa9088;
  @extend %body;
  text-align: center;
}

.img {
  width: 240px;
  height: 140px;
  margin-bottom: 16px;
  object-fit: contain;
}

.button {
  margin-top: 41px;
}

.dayCheck {
  margin-bottom: 8px;
}

.yelow {
  color: #f6d782;
  @extend %body1;
}
