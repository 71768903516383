@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.sectionTitle {
  @include typography.subhead-main-big;
  margin-bottom: 12px;
}

.items {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  overflow: hidden;
  border-radius: 16px;
  background: var(--surface-primary);
}

.transaction {
  display: flex;
  gap: 12px;
  padding: 12px 16px;
}

.iconWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--surface-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.withdrawIcon {
  width: 20px;
  height: 20px;
  color: var(--text-accent-orange);
}

.statusWrapper {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  right: -2px;
  bottom: -2px;
}

.col {
  display: flex;
  flex-direction: column;

  &.amount {
    margin-left: auto;
    align-items: flex-end;
  }
}

.txTitle {
  @include typography.subhead-main-small;
  text-transform: capitalize;
}

.address {
  @include typography.body-little;
  color: var(--text-secondary);
}

.amount {
  @include typography.body-small;
}

.date {
  @include typography.body-little;
  color: #907968;
}

.noTransactions {
  @include typography.body-small;
  text-align: center;
  padding: 40px 20px;
}
