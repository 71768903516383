:root {
  --white: #fff;
  --black: #000;
  --violet: #ad72fa;
  --sand: #ecd5b2;
  --dark-violet: #4f376e;
  --dark-purple: #3d2162;
  --deep-brown: #110007;
  --surface-bg: #181411;
  --surface-primary: #26201c;
  --surface-secondary: #322a25;
  --surface-tertiary: #413730;
  --surface-accent-orange: #e87c30;
  --gray-dark: #423034;
  --text-icn-accent-green: #abed5e;
  --action-secondary-base-disabled: #433930;
  --primary-btn-base: #4f9300;
  --primary-btn: #b8ff66;
  --primary-btn-label: #181411;
  --secondary-btn-base: #907968;
  --secondary-btn: #bdaea4;
  --text-secondary: #c9b69c;
  --text-primary: #ffebcc;
  --text-accent-orange: #ffc166;
  --green-label: #9dd65c;
  --text-stroke: #27181b;
  --decorative-background-red: #ff5340;
  --decorative-background-green: #819d31;
  --text-on-actions-dark: #181411;
  --action-primary-default: #b8ff66;
  --action-primary-active: #c4ff80;
  --action-secondary-default: #bdaea4;
  --action-secondary-base-default: #907968;
  --border-primary: #2c2520;
  --border-on-action: #181411;
  --decorative-action-red: #ff5c4f;
  --control-primary-default: #26201c;
  --border-accent: #abed5e;
  --action-secondary-disabled: #58514c;
  --text-icn-primary-active: #ffe1b2;
}
