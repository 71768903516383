@use '@app/styles/typography';

.root {
  position: absolute;
  width: 110%;
  height: 110%;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
