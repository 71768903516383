@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: var(--surface-primary);
  position: relative;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.button {
  width: 100%;
  transform: translateY(10px);
}
