@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
}

.input {
  font-family: var(--font-rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  padding: 10px 12px;
  border: 2px solid var(--border-primary);
  border-radius: 4px;
  font-size: 16px;
  background-color: #352d26;
  color: var(--text-primary);
  caret-color: #9bff28;
  outline: none;
  transition: all 0.3s ease;

  &:focus {
    border-color: #9bff28;
  }
}

.error {
  border-color: #c8422c;

  &:focus {
    border-color: #c8422c;
  }
}

.loading {
  border-color: var(--border-primary);

  &:focus {
    border-color: var(--border-primary);
  }
}

.errorMessage,
.infoMessage {
  @include typography.subhead-main-micro;
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.errorMessage {
  color: #e8a094;
}

.infoMessage {
  color: #bdaea4;

  b {
    font-weight: 700;
  }
}
