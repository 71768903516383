@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
  position: relative;
  overflow-x: hidden;
  justify-content: flex-start;
  padding-top: 150px;
}

.header {
  top: 0;
  position: fixed !important;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tabs {
  @include mixins.side-paddings;
  left: 0;
  position: fixed;
  top: 50px;
  width: 100%;
  background-color: var(--surface-bg);
  padding: 16px 16px 10px;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 0;
    right: 0;
    height: 14px;
    background: linear-gradient(180deg, var(--surface-bg), transparent);
  }
}

.content {
  width: 100%;
}
