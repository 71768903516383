.root {
  display: inline-block;
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  flex-shrink: 0;
  display: flex;
}

.root div {
  position: absolute;
  border-style: solid;
  border-color: currentColor;
  opacity: 1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: root 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.root div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes root {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }

  4.8% {
    transform: scale(0.1);
    opacity: 0;
  }

  5% {
    transform: scale(0.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
