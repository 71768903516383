@use '@app/styles/typography';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 0.4s ease-in-out;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transition: all 0.4s ease-in-out;
}

.secondary-medium,
.primary-medium {
  height: 48px;
  width: 48px;
  padding: 12px;
  border-radius: 50%;
  background-color: var(--gray-dark);

  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    transition: all 0.4s ease-in-out;
  }
}

.close-small {
  background-color: var(--surface-bg);
  border-radius: 50%;
}

.close-small,
.secondary-small,
.primary-small {
  height: 24px;
  width: 24px;

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    transition: all 0.4s ease-in-out;
  }
}

.close-medium {
  background-color: #423034;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}
