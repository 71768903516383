@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 300px;
  max-width: 100px;
  flex: 1 1 50%;
  min-width: 10px;
}

.worm {
  width: 138px;
  height: 283px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-size: 100%;
  background-repeat: no-repeat;

  &.second {
    height: 260px;
    object-fit: cover;
  }

  &.third {
    height: 240px;
  }
}

.accessory {
  width: 240px;
  height: 120px;
  position: absolute;
  left: 50%;

  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left bottom;

  &.head {
    width: 240px;
    height: 120px;
    top: -40px;
    left: 12px;
  }

  &.face {
    width: 185px;
    height: 90px;
    top: 3px;
    left: 22px;
  }

  &.body {
    // TODO
    display: none;
  }
}

.info {
  @include typography.subhead-main-small;
  position: relative;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: var(--surface-primary);
}

.name {
  color: var(--text-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
