@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  @include mixins.side-paddings;
  width: 100%;
}

.tableHead {
  @include typography.subhead-main-micro;
  display: flex;
  justify-content: space-between;
  color: var(--text-primary);
  margin-bottom: 9px;
  padding: 0 10px;
}

.separateSelfItem {
  margin-bottom: 15px;
}

.tableBody {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;

  > div {
    border-radius: 0;
  }
}
