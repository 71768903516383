@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 150px;
  background-image: url('../../assets/media/earn-hero-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  flex-grow: 1;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);

  &.noTournament {
    margin-top: -2px;
    background-color: var(--surface-secondary);
    border-radius: 16px;
    padding: 24px 0;
    position: relative;
  }
}

.phases {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.arrow {
  color: var(--text-icn-accent-green);
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
  align-self: center;
}

.spinner {
  width: 100px;
  height: 100px;
  margin: auto;
}
