@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
}

.section {
  position: relative;
  scroll-margin-top: 120px;

  &.withPadding {
    padding-top: 118px;
  }

  &.cucumbers {
    padding-top: 100px;
  }
}

.cards {
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(2, minmax(160px, 1fr));
  width: 100%;
  gap: 6px;
  margin-bottom: 8px;
  position: relative;

  @media (max-width: 360px) {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }

  > :only-child {
    grid-column: span 1;
    justify-self: stretch;
  }
}

.sectionTitle {
  @include typography.subhead-main-big;
  color: var(--text-primary);
  margin: 10px 0 8px;
}

.itemCards {
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  width: 100%;
  gap: 4px;
  margin-bottom: 32px;
  position: relative;

  @media (max-width: 360px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  > :only-child {
    grid-column: span 1;
    justify-self: stretch;
  }
}
