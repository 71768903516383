@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  padding-top: 112px;
  overflow-x: hidden;
}

.header {
  top: 0;
  position: fixed;
}

.tabs {
  @include mixins.side-paddings;
  left: 0;
  position: fixed;
  top: 50px;
  width: 100%;
  background-color: var(--surface-bg);
  padding: 16px 16px 10px;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 0;
    right: 0;
    height: 14px;
    background: linear-gradient(180deg, var(--surface-bg), transparent);
  }
}

.content {
  display: flex;
  padding-top: 16px;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
}

.dev {
  @include mixins.side-paddings;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  padding-top: 23px;
  justify-content: center;

  * {
    font-size: 12px;
  }
}
