@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  background-color: var(--surface-primary);
}

.title {
  text-align: center;
  color: #ecd5b2;
  @extend %h-2;
  margin-bottom: 20px;
}

.desc {
  color: #aa9088;
  text-align: center;
  @extend %body;
  margin-bottom: 24px;
}

.img {
  object-fit: contain;
}

.bottom {
  padding: 16px 16px 0;
}

.defult {
  padding: 16px;
}
