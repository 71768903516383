@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  padding-bottom: 250px;
  position: relative;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
  text-align: center;
  position: relative;
}

.description {
  @include typography.subhead-main-big;
  color: var(--text-secondary);
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  align-items: center;
  margin-bottom: 8px;
}

.heroImg {
  position: absolute;
  width: 303px;
  height: 303px;
  object-fit: contain;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}
