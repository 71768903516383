@use '@app/styles/typography';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  padding: 12px 16px;
  background-color: var(--surface-primary);
}

.contentWrap {
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 0;
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
}

.index {
  display: flex;
  align-items: center;
  justify-content: center;
  @extend %body;
  color: #ecd5b2;
  min-width: 32px;
}

.date {
  display: flex;
  align-items: center;
  @extend %title-2;
  color: #aa9088;
}

.bonus {
  display: flex;
  align-items: center;
  @extend %title-2;
  color: #f6d782;
}

.title {
  @extend %title;
  color: #ecd5b2;
  margin-bottom: 4px;
}

.desc {
  color: #aa9088;
  @extend %body;
}

.imgWrap {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(94, 63, 44, 0.25);
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.userName {
  @extend %title;
  color: #ecd5b2;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
