@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 16px;
  background: var(--surface-secondary);
  gap: 12px;
  color: var(--text-primary);
  text-align: left;
}

.title {
  @include typography.subhead-main-big;
  margin-bottom: 4px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px;
}

.label {
  @include typography.body-small;
  color: var(--text-secondary);
}

.value {
  @include typography.subhead-main-little-medium;
  word-break: break-all;
}

.buttonView {
  margin-top: 4px;
  align-self: flex-start;
}
