@use '@app/styles/typography';

.root {
  @include typography.h-2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  color: var(--text-primary);
}

.rankImage {
  width: 100%;
  height: 100%;
  position: relative;
}

.level {
  position: absolute;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--border-primary);
}
