@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  display: flex;
  gap: 8px;
  color: var(--text-primary);
  padding: 12px 16px 12px 8px;
  align-items: center;
  border-radius: 16px;
  border-bottom: 1px solid var(--border-primary);
  background: var(--surface-primary);
}

.place {
  @include typography.badge-2;
  width: 44px;
  text-align: center;
}

.avatarFrame {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: var(--surface-bg);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0 6px 0 2px var(--surface-primary);
  }
}

.avatar {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  object-fit: contain;
}

.name {
  width: 100%;
  padding-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.place,
.avatarFrame,
.rating {
  flex-grow: 0;
  flex-shrink: 0;
}

.name,
.rating {
  @include typography.subhead-main-big;
}
