@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  height: 100%;
  justify-content: flex-start;
}

.contentClassName {
  height: 100%;
  border-radius: 0;
  padding: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 16px 101px;
  height: 100%;
  background: url('../../assets/media/skills-background.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  color: var(--text-primary);
  text-align: center;
}

.image {
  width: 192px;
  height: 192px;
  object-fit: contain;
}

.title {
  @include typography.h-2;
  margin: 4px 0 6px;
}

.description {
  @include typography.subhead-main-small;
}
