@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 32px;
}

.image {
  width: 134px;
  height: 140px;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
  text-align: center;
  margin-top: 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  width: 100%;
}
