@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
  background-color: #110007;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 423px;
    padding: 32px 16px 66px;
    height: 100%;
    width: 100%;
    transform: translateY(100%);
    transition: 0.8s;
  }
}

.open {
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  visibility: visible;

  .content {
    width: 100%;
    transform: translateY(0);
  }
}

.closeBtn {
  position: absolute;
  top: 32px;
  right: 16px;
}
