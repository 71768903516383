@use '@app/styles/typography';

.root {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 16px;
  transition: all 0.3s ease;

  &.interactive:active {
    background-color: var(--surface-secondary);
  }
}

.image {
  width: 24px;
  height: 24px;
  object-fit: contain;
  flex-shrink: 0;
}

.title {
  @include typography.subhead-main-big;
  color: var(--text-primary);
  margin-right: auto;
  flex-shrink: 0;
}

.value {
  @include typography.subhead-main-little-medium;
  color: var(--text-secondary);
  word-break: break-all;
}
