@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: stretch;
  width: 100%;
}

.phaseTitle {
  @include typography.h-2;
  color: var(--text-primary);
}

.description {
  @include typography.subhead-main-small;
  color: var(--text-secondary);
  max-width: 269px;
  align-self: center;
}

.rewardImage {
  width: 200px;
  height: 200px;
  object-fit: contain;
  align-self: center;
}

.diamondReward {
  @include typography.h-3;
  align-items: center;
  justify-content: center;
  display: flex;
  color: var(--text-accent-orange);
  padding: 21px 0;

  span {
    padding-left: 8px;
    padding-right: 4px;
  }
}

.diamondImage {
  width: 140px;
  height: 90px;
  object-fit: contain;
  align-self: center;
}
