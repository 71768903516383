@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 90px;
}

.content {
  @include mixins.side-paddings;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: 100%;
  flex-shrink: 0;
  padding-top: 16px;
  padding-bottom: 120px;
  // background-image: url('../../assets/media/earn-hero-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 100%;
}

.streak {
  flex-shrink: 0;
  background-color: var(--surface-primary);
}
