@use '@app/styles/typography';

.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 356px;
  height: 356px;
  width: 100%;
  z-index: 0;
}

.cover,
.stateIndicator,
.sectorDiagram {
  position: absolute;
  object-fit: contain;
}

.sectorDiagramBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--surface-tertiary);
  border-radius: 50%;
}

.sectorDiagram {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  object-position: top center;
  transition: transform 0.5s ease-out;

  &.visible {
    transform: translateX(-50%) rotate(0deg);
  }
}

.cover {
  width: 126%;
  height: auto;
  left: 50%;
  top: -16px;
  z-index: 3;
  transform: translateX(-50%);
}

.stateIndicator {
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
