@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 32px;
  border-radius: 20px 20px 0 0;
  background: var(--surface-primary);
}

.name {
  @include typography.h-2;
  color: var(--text-primary);
  margin-bottom: 8px;
}

.bonus {
  margin: 8px 0 32px;
}

.buttons {
  margin-top: 16px;
}
