@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  position: relative;
  user-select: none;
  display: flex;
  justify-content: center;
  touch-action: none !important;
  z-index: 0;
  height: 100vh;
  width: 100%;
}

.worm {
  position: relative;
  overflow: hidden;
  touch-action: none !important;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.tapArea {
  touch-action: none !important;
  position: absolute;
  width: 100%;
  height: 75%;
  bottom: 0;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
}
