@use '@app/styles/typography';
@use '@app/styles/colors';

.root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  background-color: var(--surface-secondary);
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  @extend %title;
  color: #ecd5b2;
  margin-bottom: 4px;
}

.desc {
  color: #aa9088;
  @extend %body;
}

.iconWrap {
  display: flex;
  justify-content: center;
  width: 120px;
  height: 70px;
  flex-shrink: 0;
}

.icon {
  height: 70px;
}

.limited {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.limitedDesc {
  color: #ecd5b2;
  @extend %body;
  font-weight: 600;
}

.wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 8px;
}

.completed {
  @extend %body;
  color: #aa9088;
  display: flex;
  gap: 4px;
}

.available {
  @extend %body;
  color: #aa9088;
  display: flex;
  gap: 6px;
}

.indicator {
  position: absolute;
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f00;
  z-index: 10;
  right: 16px;
  top: 16px;
  animation: pulseIndicator 2.5s infinite linear;
}

@keyframes pulseIndicator {
  0% {
    scale: 1;
  }

  50% {
    scale: 0.8;
  }

  100% {
    scale: 1;
  }
}
