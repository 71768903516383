@use 'typography';
@use 'reset';
@use 'mixins';
@use 'colors';
@use 'media';
@use 'fonts';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
  --font-rubik: 'Rubik', sans-serif;
  --font-inter: 'Inter', sans-serif;
  --font-troika: 'Troika', sans-serif;
  --font-rubik: 'Rubik', sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  background-color: var(--surface-bg);
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

html {
  user-select: none;
  height: 100vh;
  height: 100dvh;
}

body {
  height: 100%;
  overflow-y: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  min-height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.touch-action {
  touch-action: none;
}

[data-tc-wallets-modal-container='true'] {
  font-family: var(--font-rubik);
  color: var(--text-primary);
}

[data-tc-h1='true'] {
  font-family: var(--font-troika);
  font-size: 24px;
  color: var(--text-primary);
}

[data-tc-h2='true'] {
  color: var(--text-secondary);
}
