@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  gap: 4px;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.avatar {
  transform: translateY(2px);
}

.name {
  @include typography.subhead-main-small;
  color: var(--text-primary);
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.hp {
  height: 8px;
  border-radius: 4px;
  margin-top: 8px;
  width: 108px;
  margin-left: 4px;
}

.mirror {
  align-items: flex-end;

  .row {
    justify-content: flex-end;
    min-width: 0;
  }

  .avatar {
    order: 10;
    transform: scaleX(-1) translateY(2px);
  }

  .name {
    text-align: right;
  }

  .skills {
    justify-content: flex-end;
  }

  .hp {
    transform: scaleX(-1);
    margin-left: unset;
    margin-right: 4px;
  }
}
