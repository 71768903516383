:root {
  --stroke-text-color: #ecd5b2;
  --stroke-color: #27181b;
}

.root {
  position: relative;
  text-align: center;
  color: var(--stroke-color);
  -webkit-text-stroke-width: var(--stroke-width);
  -webkit-text-stroke-color: var(--stroke-color);

  &::before {
    content: attr(data-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--outlined-text-outline-color);
    -webkit-text-stroke-width: var(--stroke-width);
    -webkit-text-stroke-color: var(--outlined-text-outline-color);
    margin-top: var(--stroke-offset);
  }

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--stroke-text-color);
    -webkit-text-stroke-width: 0;
  }
}
