@use '@app/styles/typography';

.root {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
}

.balanceIcon {
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
  align-self: center;
}

.balanceValue {
  @include typography.h-5;
  color: var(--text-primary);
  text-align: center;
}

.playerInfo {
  flex: 1;
  min-width: 0;
}
