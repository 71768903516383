@use '@app/styles/typography';

.root {
  position: relative;
  flex-shrink: 0;
}

.statBar {
  display: flex;
  align-items: center;

  .skillIcon {
    width: 16px;
    height: 16px;
  }

  .levelValue {
    @include typography.subhead-main-micro;
    color: var(--text-secondary);
  }
}

.skillsList {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  .skillIcon {
    width: 47px;
    height: 47px;
  }

  .levelValue {
    display: none;
  }
}
