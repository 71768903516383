@use '@app/styles/typography';

.bottomSheet {
  align-items: center;
  text-align: center;
  padding-bottom: 32px;
}

.walletImage {
  width: 134px;
  height: 134px;
  margin-bottom: 24px;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
  margin-bottom: 4px;
}

.address,
.description {
  @include typography.subhead-main-big;
  color: var(--text-primary);
}

.address {
  word-break: break-all;
}

.connectionButton {
  align-self: stretch;
  margin-top: 32px;
}
