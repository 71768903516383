@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  background-image: url('../../assets/media/battle-background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
