.root {
  position: relative;
  width: 100%;
}

.bgImage {
  position: absolute;
  width: 100%;
  height: auto;
  top: -120px;
}

.wrapper {
  position: absolute;
  left: 0;
  right: 0;
}

.hero,
.text {
  object-fit: contain;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.hero {
  width: 270px;
  height: 270px;
  top: 0;
}

.text {
  width: auto;
  height: 94px;
  top: 200px;
}
