@use '@app/styles/typography';

.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(24, 20, 17, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.msgWrap {
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--surface-tertiary);
  max-width: 316px;
  z-index: 2;
}

.msg {
  color: var(--text-primary);
  @extend %h-2-extra;
  text-align: center;

  span {
    color: var(--text-accent-orange);
  }
}

.arrow {
  width: 48px;
  height: 48px;
  color: var(--text-icn-accent-green);
}

.arrowWrap {
  position: absolute;
  animation: arrowMovement 1s ease-in-out infinite;
}

.circlesWrapper {
  animation: circleMovement 1.5s ease-in-out infinite;
  width: 421px;
  height: 421px;
  position: relative;
  z-index: 1;
}

.targetCircle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid var(--text-icn-accent-green);

  &:first-child {
    width: 315px;
    height: 315px;
  }

  &:nth-child(2n) {
    width: 371px;
    height: 371px;
  }

  &:last-child {
    width: 421px;
    height: 421px;
  }
}

@keyframes arrowMovement {
  50% {
    transform: translateY(6px);
  }
}

@keyframes circleMovement {
  50% {
    transform: scale(0.95);
  }
}
