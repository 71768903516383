@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  width: 100%;
  position: relative;

  .tabs {
    width: 100%;
    position: relative;
    display: flex;
    list-style: none;
  }
}

.primary {
  .tabs {
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid var(--gray-dark);
    background: var(--surface-primary);

    &::after {
      @extend %tab-active;

      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: calc(100% / var(--tab-count));
      background-color: var(--surface-secondary);
      z-index: 1;
      transform: translateX(var(--tab-active-indicator-translate));
      transition: transform 0.6s ease-in-out;
      color: var(--text-secondary);
    }
  }
}

.secondary {
  padding: 6px;
  border-radius: 16px;
  background: var(--surface-primary);

  .tabs {
    display: flex;

    &::after {
      @extend %tab-active;
      // content: attr(data-active-tab-label);
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: calc(100% / var(--tab-count));
      background-color: var(--surface-secondary);
      border-radius: 10px;
      z-index: 1;
      transform: translateX(calc(var(--tab-active-indicator-translate)));
      transition: transform 0.6s ease-in-out;
      color: var(--text-secondary);
    }
  }
}

.tab {
  @extend %tab;

  position: relative;
  z-index: 2;
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: var(--text-secondary);
}

.tab-primary {
  height: 48px;
  // border-bottom: 4px solid transparent;
  transition: all 0.3s ease-in-out;

  &.active {
    // border-bottom: 4px solid #ecd5b2;
    @extend %tab-active;
    color: var(--text-primary);
  }
}

.tab-secondary {
  height: 35px;
  border-radius: 10px;

  &.active {
    @extend %tab-active;
    color: var(--text-primary);
    transition: all 0.3s ease-in-out;
    transition-delay: 0.4s;
  }
}
