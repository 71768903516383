.root {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--text-accent-orange);
}

.bonusIcon {
  width: 16px;
  height: 16px;
}

.price {
  margin-top: auto;
}
