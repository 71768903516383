@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 16px;
  overflow-x: hidden;
  padding-bottom: 150px;
  background-image: url('../../assets/media/earn-hero-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
}

.title {
  @include mixins.side-paddings;
  @include typography.h-2;
}

.description {
  @include mixins.side-paddings;
  @include typography.subhead-main-small;
  color: var(--text-secondary);
  max-width: 320px;
  margin-top: 6px;
  margin-bottom: 8px;
}

.heroImage {
  width: 342px;
  height: 289px;
  margin-top: 3px;
  margin-bottom: -40px;
}

.tabs {
  @include mixins.side-paddings;
  position: relative;
  width: 100%;
}

.content {
  @include mixins.side-paddings;
  width: 100%;
  overflow-x: hidden;
}
