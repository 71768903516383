@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 200px;
  background-image: url('../../assets/media/earn-hero-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  flex-grow: 1;
  overflow-x: hidden;
}

.loader {
  width: 100px;
  height: 100px;
  margin: auto;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
}

.description {
  @include typography.subhead-main-big;
  margin-top: 6px;
  color: var(--text-secondary);
  max-width: 294px;
}

.winners {
  display: flex;
  gap: 8px;
  margin-top: 20px;
}

.table {
  margin-top: 22px;
}

.comingSoon {
  color: var(--text-accent-orange);
  text-align: center;
  font-family: Troika;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  margin-top: 8px;
}

.comingSoonImage {
  width: 265px;
  height: 237px;
  object-fit: contain;
  margin-top: 5px;
}
