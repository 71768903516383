@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  position: relative;
  user-select: none;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.avatarNameImage {
  position: absolute;
  max-width: 377px;
  padding: 0 35px;
  width: 100vw;
  height: auto;
  top: 72px;
  left: 50%;
  transform: translate(-50%, 400px);
  z-index: 0;
  opacity: 0;

  &.loaded {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.worm {
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateY(600px);
  z-index: 1;

  &.loaded {
    opacity: 1;
    transform: translateY(0);
  }
}

.avatarNameImage,
.worm {
  transition: all 0.5s 0.1s ease;
}
