@use '@app/styles/typography';

.root {
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  color: #ecd5b2;
}

.unfilled {
  @extend %badge-1;

  .img {
    padding: 0 2px 3px;
  }
}

.default {
  background-color: var(--decorative-background-red);
  padding: 0 8px;
  border-radius: 1000px;
  @extend %badge;

  .img {
    padding: 0 2px 3px;
  }
}

.inactive {
  color: #aa9088;
  padding: 0 8px;
  border-radius: 1000px;
  @extend %badge;
}

.completed {
  color: #ddf892;
  background-color: var(--decorative-background-green);
  padding: 0 8px;
  border-radius: 1000px;
  @extend %badge;
}

.pending {
  color: var(--text-accent-orange);
  background-color: #5c3b0a;
  padding: 0 8px;
  border-radius: 1000px;
  @extend %badge;
}

.small {
  height: 24px;
  gap: 4px;

  .img {
    flex: 0 0 auto;
    width: 23px;
    height: 19px;
    object-fit: contain;
  }
}

.medium {
  height: 32px;
  gap: 4px;

  .img {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

.large {
  height: 54px;
  gap: 11px;

  .img {
    flex: 0 0 auto;
    width: 54px;
    height: 54px;
    object-fit: contain;
  }

  .value {
    @extend %h-1;
  }
}

.combined {
  height: 54px;
  gap: 11px;

  .img {
    flex: 0 0 auto;
    width: 54px;
    height: 54px;
    object-fit: contain;
  }

  .value {
    @extend %h-2;
  }
}

.header-cucumber,
.header-tomato,
.header-medals,
.header-silver-tickets {
  @extend %badge-2;
  color: var(--text-primary);
  background-color: transparent;
  align-self: unset;

  .img {
    padding: 0;
    width: 17px;
    height: 18px;
  }
}
