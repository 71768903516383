@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #110007;
  padding: 20px;
}

.text {
  @include typography.body;
  text-align: center;
  margin-bottom: 20px;
  color: #ecd5b2;
}
