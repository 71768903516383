.root {
  display: flex;
  gap: 24px;
  padding: 0 16px;
  justify-content: center;
  height: 83px;
  position: relative;
  width: 100%;
}

.iconBg {
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: var(--surface-secondary);
  border-radius: 50%;
}

.img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.button {
  max-width: 156px;
  transform: translateY(10px);
}

.rank {
  transform: translateY(-5px);
}

.skills {
  transform: translateY(5px);
}

.background {
  position: absolute;
  top: -16px;
  left: 50%;
  bottom: -40px;
  transform: translate(-50%, 0);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background: var(--surface-primary);
  width: 120vw;
  z-index: 0;
  pointer-events: none;
}

.changeButton {
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(calc(-50% - 115px));
}

.changeImage {
  width: 64px;
  height: 64px;
  transform: translateY(-9px);
}

.changeLabel {
  transform: translateY(-9px);
}

.equipButton {
  position: absolute;
  bottom: 169px;
  left: 50%;
  transform: translateX(calc(-50% + 115px));
}

.iconBg {
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: var(--surface-secondary);
  border-radius: 50%;
}

.equipImg {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
