@use '@app/styles/typography';

.root {
  max-width: 356px;
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.text {
  @extend %h-1-extra;
}
