@use '@app/styles/typography';

.barFill {
  max-width: 100%;
}

.rounded,
.rectangular {
  width: 100%;
  display: flex;

  .bar {
    background: var(--bar-bg-color);
    width: 100%;

    height: 16px;

    .barFill {
      height: 16px;
      background: var(--fill-bar-color);
      transition: all 0.3s ease-in-out;
    }
  }
}

.rounded {
  .bar {
    border-radius: 50px;
    width: 100%;

    .barFill {
      border-radius: 50px;
    }
  }
}

.rectangular {
  color: var(--text-secondary);
  gap: 4px;

  .bar {
    border-radius: 4px;

    .barFill {
      border-radius: 4px;
    }
  }
}

.vertical {
  display: flex;
  position: relative;
  width: 16px;
  height: 290px;
  flex-shrink: 0;
  border-radius: 1000px;
  background: var(--surface-secondary);
  padding: 6px 4px 32px;

  .bar {
    display: flex;
    align-items: flex-end;
    background: var(--bar-bg-color);
    width: 100%;
    border-radius: 1000px;
    height: 100%;

    .barFill {
      width: 100%;
      border-radius: 1000px;
      background: var(--fill-bar-color);
      transition: all 0.3s ease-in-out;
    }
  }

  .round {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    flex-shrink: 0;
    background: var(--surface-secondary);
  }

  .roundFill {
    background: var(--fill-bar-color);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    flex-shrink: 0;
    // animation: blink 1s linear infinite;
  }
}

.custom {
  width: 100%;
  display: flex;
  overflow: hidden;

  .bar {
    background: var(--bar-bg-color);
    width: 100%;

    height: 100%;

    .barFill {
      height: 100%;
      background: var(--fill-bar-color);
      transition: all 200ms linear;
    }
  }
}

.digits {
  @extend %days;
}

.orange {
  color: var(--text-accent-orange);
}

@media (max-height: 600px) {
  .vertical {
    height: 220px;
  }
}

@keyframes blink {
  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 0.5;
  }
}
