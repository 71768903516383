@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  padding: 20px 16px;
  background-color: var(--surface-primary);
  width: 100%;
  position: relative;
  flex-shrink: 0;
  padding-right: 120px;
}

.title {
  @include typography.h-4;
  color: var(--text-primary);
  position: relative;
}

.description {
  @include typography.subhead-main-little-medium;
  color: var(--text-secondary);
  margin: 4px 0 16px;
  position: relative;
  max-width: 240px;
}

.button {
  width: fit-content;
}

.image {
  position: absolute;
  width: 182px;
  height: 154px;
  bottom: 0;
  right: 0;
}
