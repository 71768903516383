@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: baseline;
}

.level {
  @include typography.h-5;
  margin-right: 8px;
  color: var(--text-secondary);
  display: flex;
  gap: 1px;

  span:first-child {
    color: var(--text-primary);
  }
}

.skillName {
  @include typography.subhead-main-big;
  color: var(--text-primary);
}

.bar {
  margin: 3px 0 4px;
}

.description {
  @include typography.body-little;
  color: var(--text-secondary);
}
