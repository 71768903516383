@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  @include mixins.side-paddings;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 16px;
  background-image: url('../../assets/media/earn-hero-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  align-items: center;
  overflow-x: hidden;
  text-align: center;
  padding-bottom: 150px;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
}

.description {
  @include typography.subhead-main-big;
  color: var(--text-secondary);
  margin-top: 6px;
  margin-bottom: 30px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, minmax(161px, 1fr));
  width: 100%;
  gap: 6px;
  margin-bottom: 8px;

  @media (max-width: 360px) {
    grid-template-columns: repeat(auto-fit, minmax(161px, 1fr));
  }

  > :only-child {
    grid-column: span 1;
    justify-self: stretch;
  }
}
