.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  flex-shrink: 0;
}

.button {
  margin-top: 16px;
}

.status {
  margin-top: 32px;
}
