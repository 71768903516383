@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;
  @include mixins.max-width;
  position: fixed;
  width: 100%;
  // bottom: 20px;
  top: -10%;
  left: 50%;
  // transform: translateX(100%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease-in-out;
  pointer-events: none;
  z-index: 1000;
}

.visible {
  top: 40px;
  left: 50%;
  //  transform: translateX(-50%);
  opacity: 1;
  visibility: visible;
  transition: all 0.6s ease-in-out;
}
