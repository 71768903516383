@use '@app/styles/typography';

.root {
  display: flex;
  padding: 16px 12px 16px 16px;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  border-radius: 16px;
  background: var(--surface-secondary);
}

.button {
  width: fit-content;
  padding: 8px 16px 8px 12px;
}

.usdEquiv {
  @include typography.body-little;
  color: var(--text-secondary);
  margin-right: auto;
}
