@use '@app/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  flex-grow: 1;
}

.menuNav {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%) translateZ(200px);
  z-index: 10;
}

.navSideBar {
  position: absolute;
  top: 15%;
  right: 12px;
  z-index: 10;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
