@use '@app/styles/typography';

.root {
  display: flex;
  gap: 19px;
  width: 100%;
}

.skill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  flex: 1 1 auto;
  min-width: 70px;
}

.level {
  @include typography.h-4;
  color: var(--text-primary);
}

.name {
  @include typography.subhead-main-small;
  color: var(--text-primary);
}
