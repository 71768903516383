@use '@app/styles/typography';

.root {
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: stretch;
  border-radius: 16px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: var(--surface-primary);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-bottom: 8px;
}

.title {
  @include typography.h-2;
  color: var(--text-primary);
}

.subtitle {
  font-family: var(--font-troika);
  font-size: 16px;
  color: var(--text-accent-orange);
  margin-bottom: 9px;
}

.image {
  width: 100%;
  align-self: center;
  object-fit: contain;
}

.button {
  margin-top: -14px;
}
