.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  position: relative;
}

.container {
  display: flex;
  width: 100%;
  height: 68px;
  padding: 0 8px 0 4px;
  align-items: center;
  border-radius: 8px;
  background: var(--surface-primary);
  box-shadow: 0 0 15px transparent;
  transition: box-shadow 0.5s ease;

  &.animating {
    box-shadow: 0 0 5px var(--text-icn-accent-green);
  }
}

.info {
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 16px;
}

.button {
  width: fit-content;
  margin-left: auto;
  gap: 4px;
  transition: all 0.3s ease;
}
