@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  @include mixins.side-paddings;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  position: relative;
}
