@use '@app/styles/typography';

.buttonNickname {
  @include typography.subhead-main-little-medium;
  color: var(--text-secondary);
  overflow: hidden;
  margin-left: 20px;

  span {
    display: block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
