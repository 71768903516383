@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;
  width: 100%;
  flex-shrink: 0;
}

.tableHead {
  @include typography.subhead-main-micro;
  display: flex;
  justify-content: space-between;
  color: var(--text-primary);
  margin-bottom: 9px;
  padding: 0 16px;
}

.tableBody {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;

  > div {
    border-radius: 0;
  }
}

.item {
  display: flex;
  gap: 8px;
  color: var(--text-primary);
  padding: 4px 16px 4px 8px;
  align-items: center;
  border-radius: 16px;
  border-bottom: 1px solid var(--border-primary);
  background: var(--surface-primary);
}

.place {
  @include typography.badge-2;
  width: 44px;
  text-align: center;
}

.avatar {
  bottom: 0;
  transform: translateY(4px) scaleX(-1);
}

.name {
  width: 100%;
  padding-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.place,
.avatarFrame,
.rating {
  flex-grow: 0;
  flex-shrink: 0;
}

.name,
.rating {
  @include typography.subhead-main-big;
}
