@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  padding: 0 0 32px;
  z-index: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  gap: 32px;
  max-width: 294px;
}

.img {
  margin-bottom: 32px;
  z-index: 1;
}

.text {
  @include typography.days-widget;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  color: var(--text-secondary);
}

.orange {
  color: var(--text-accent-orange);
}

.title {
  @include typography.h-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--text-accent-orange);
  transform: rotate(-7.11deg);
}
