@use '@app/styles/typography';

.root {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 12px;
  border-radius: 16px;
  background: var(--surface-secondary);
  width: 100%;
  align-self: stretch;
  position: relative;
}

.button {
  margin-top: 12px;
}
