@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.rankBadge {
  width: 128px;
  height: 128px;
  font-size: 64px;
}

.expBar {
  margin-top: -40px;
}

.nameWrapper {
  @include mixins.side-paddings;
  position: relative;
  width: 100%;
}

.navButton {
  position: absolute;
  top: 50%;
  color: var(--text-primary);
  z-index: 1;

  &:disabled .arrow {
    opacity: 0;
    pointer-events: none;
  }
}

.prev {
  left: 24px;
  transform: scaleX(-1) translateY(-50%);
}

.next {
  right: 24px;
  transform: translateY(-50%);
}

.arrow {
  width: 24px;
  height: 24px;
  color: var(--text-primary);
}
