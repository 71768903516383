@use '@app/styles/mixins';

@property --eye-radius-x {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 60%;
}

@property --eye-radius-y {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 30%;
}

.root {
  @include mixins.max-width;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 2;
  pointer-events: none;
}

.eye {
  position: relative;
  width: 102vw;
  height: 102vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    ellipse var(--eye-radius-x) var(--eye-radius-y) at 50% 45%,
    transparent calc(var(--eye-radius-x) - 1%),
    #000 100%
  );
  animation: blink 1.5s ease forwards;
  opacity: 1;
  backdrop-filter: blur(1px);
}

@keyframes blink {
  0% {
    --eye-radius-x: 60%;
    --eye-radius-y: 20%;
  }

  10%,
  50%,
  90% {
    --eye-radius-x: 60%;
    --eye-radius-y: 1%;
  }

  30%,
  70% {
    --eye-radius-x: 60%;
    --eye-radius-y: 15%;
  }

  100% {
    --eye-radius-x: 100%;
    --eye-radius-y: 100%;
  }
}
