@use '@app/styles/typography';

.root {
  display: flex;
  width: 100%;
  padding-top: 32px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
  background: var(--surface-secondary);
  flex-shrink: 0;
}

.title {
  font-family: var(--font-troika);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: var(--text-primary);
}

.description {
  font-family: var(--font-rubik);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  color: var(--text-secondary);
}

.image {
  width: 266px;
  height: 191px;
  margin-bottom: -4px;
}
