@use '@app/styles/typography';

.root {
  align-items: stretch;
  text-align: center;
  padding-bottom: 32px;
  color: var(--text-primary);
}

.title {
  @include typography.h-3;
  margin-bottom: 8px;
}

.description {
  font-family: var(--font-rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;

  b {
    font-weight: 700;
  }
}

.confirm {
  margin-top: 32px;
  margin-bottom: 30px;
}
