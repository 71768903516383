.root {
  position: relative;
}

.league {
  width: 48px;
  height: 48px;
}

.worm {
  position: absolute;
  width: 44px;
  height: 44px;
  left: 2px;
  bottom: 9px;
}
