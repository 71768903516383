@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  @include mixins.side-paddings;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;
  background-image: url('../../assets/media/skills-background.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  padding-bottom: 150px;
}

.pageBackground {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
}

.balance {
  z-index: 2;
  margin: 0 auto;
  margin-top: -50px;

  &::before {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    height: 163px;
    width: 100vw;
    background: linear-gradient(0deg, var(--surface-bg) 65%, transparent 100%);
    z-index: 0;
  }
}

.skillsList {
  margin-top: 28px;
  position: relative;
  z-index: 2;
  background-color: var(--surface-bg);
}

.worm {
  flex: 1 1 auto;
  height: 250px;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateY(200px);
  pointer-events: none;
  overflow: hidden;

  &.loaded {
    opacity: 1;
    transform: translateY(0);
  }
}
