@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  position: relative;
  background-image: url('../../assets/media/game_bg.png');
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.image {
  @include mixins.max-width;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
}

.worms {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.status {
  position: absolute;
  left: 50%;
  top: 67%;
  transform: translateX(-50%);
  min-width: 250px;
  width: 100%;
  z-index: 1;
}

.playersBar {
  padding: 4px 4px 0;
  z-index: 2;
}

.cryptoRewardImg {
  animation: float 3s ease-in-out infinite;
  animation-delay: 2s;
  width: 276px;
  height: 276px;
  position: absolute;
  left: 50%;
}

@keyframes float {
  0% {
    transform: translate(-50%, 50px);
  }

  50% {
    transform: translate(-50%, 75px) scale(1.05);
  }

  100% {
    transform: translate(-50%, 50px);
  }
}
