@use '@app/styles/media';
@use '@app/styles/typography';

.root {
  padding: 0 24px;
  color: var(--text-primary);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include typography.h-2;
  text-align: center;
}

.dots {
  display: inline-block;
  width: 0;
}

.timer {
  @include typography.subhead-main-micro;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0 32px;
  width: 100%;
}

.button {
  width: 100%;
  max-width: 156px;
}
