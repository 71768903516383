@use '@app/styles/typography';

.root {
  display: flex;
  width: 100%;
  gap: 6px;
}

.error,
.success,
.info {
  .msg {
    @extend %alert;
    color: #ecd5b2;
    word-break: break-all;
  }
}

.warning {
  .msg {
    color: var(--surface-bg);
    @extend %alert;
  }
}

.error {
  .icon {
    width: 17px;
    height: 20px;
    object-fit: contain;
  }
}

.warning,
.success {
  .icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.info {
  .icon {
    width: 23px;
    height: 19px;
    object-fit: contain;
    padding: 0 1.667px 2.5px;
  }
}
