@use '@app/styles/mixins';
@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.wormWrapper {
  flex: 1 1 100%;
  max-height: 340px;
  min-height: 260px;
  transition-property: all;
  transition-timing-function: ease-in-out;
  opacity: 0;
  transform: translateY(300px);
  pointer-events: none;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.worm {
  width: 100%;
  height: 100%;
}

.wrapper {
  background-color: var(--surface-bg);
  flex: 1 1 auto;
  min-height: 150px;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 15px;
    pointer-events: none;
  }

  &::before {
    top: 0;
    z-index: 2;
    background: linear-gradient(to top, transparent, var(--surface-bg));
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to bottom, transparent, var(--surface-bg));
  }
}

.scrollContainer {
  @include mixins.side-paddings;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 60px;
}

.itemsContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  align-items: stretch;
  width: 100%;
  gap: 8px;
  width: 100%;
  position: relative;

  > :only-child {
    grid-column: span 1;
  }
}

.bottomButton {
  flex-shrink: 0;
  position: relative;
}
