@use '@app/styles/typography';

.root {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.entryImage {
  width: 84px;
  height: 84px;
  object-fit: contain;
}

.entryInfo {
  @include typography.subhead-main-little-medium;
  display: flex;
  align-items: flex-start;
  gap: 2px;
  flex-direction: column;
  color: var(--text-secondary);
}

.fee {
  @include typography.subhead-main-small;
  color: var(--text-primary);
}

.priceInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.priceTitle {
  @include typography.subhead-main-small;
  color: var(--text-primary);
}

.priceWrapper {
  display: flex;
  gap: 4px;
}

.starsWrapper {
  @include typography.subhead-main-micro;
  display: flex;
  gap: 4px;
  color: var(--text-secondary);
  align-items: center;
}

.starsIcon {
  width: 16px;
  height: 16px;
}
