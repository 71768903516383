@use '@app/styles/typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  background: var(--surface-secondary);
  padding: 4px 4px 8px;
}

.image {
  max-width: 80px;
  max-height: 80px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1;
}

.amount {
  @include typography.subhead-main-little-semibold;
  color: var(--text-primary);
}

.starsPrice {
  margin-top: 4px;
}
