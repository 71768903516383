@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.max-width;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 191px;

  @media (max-height: 620px) {
    margin-bottom: 130px;
  }
}
