@use '@app/styles/media';
@use '@app/styles/typography';
@use '@app/styles/mixins';

.root {
  @include mixins.side-paddings;
  padding-top: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
