@use '@app/styles/typography';

.root {
  position: relative;
  display: flex;
  z-index: 0;
  padding-top: 16px;
  height: 218px;
  width: 100%;
  pointer-events: none;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 356px;
  height: 356px;
  width: 100%;

  z-index: 0;
}

.arrow {
  z-index: 4;
}

.timer {
  position: absolute;
  top: -25px;
  left: 16px;
  z-index: 0;
}
